import request from '@/utils/request'

/**
 *
 * @param {超管列表} data
 * @returns
 */
export function adminList(data) {
  return request({
    url: '/superAdmin/user/index',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {管理员详情} data
 * @returns
 */
export function adminDetail(data) {
  return request({
    url: '/superAdmin/user/view',
    method: 'post',
    data,
  })
}

/**
 * @创建管理员
 */
export function createAdmin(data) {
  return request({
    url: '/superAdmin/user/create',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {删除管理员} data
 * @returns
 */
export function deleteAdmin(data) {
  return request({
    url: '/superAdmin/user/delete',
    method: 'post',
    data,
  })
}
/**
 *
 * @param {修改管理员账户} data
 * @returns
 */
export function editAdmin(data) {
  return request({
    url: '/superAdmin/user/edit',
    method: 'post',
    data,
  })
}

/**
 * @职务下拉
 */
export function jobSelect(data) {
  return request({
    url: '/superAdmin/user/role-option',
    method: 'post',
    data,
  })
}
/**
 * @封存启用管理员
 */
export function stopAdmin(data) {
  return request({
    url: '/superAdmin/user/status',
    method: 'post',
    data,
  })
}
/**
 * @权限列表
 */
export function permissionDetail(data) {
  return request({
    url: '/superAdmin/authority/auth-list',
    method: 'post',
    data,
  })
}
