import request from '@/utils/request'

// brand 表格数据
export function getBrandTableList(data) {
  return request({
    url: '/baseAdmin/brand/index',
    method: 'post',
    data,
  })
}

// 获取 快速添加品牌数据
export function getQuickAddList(data) {
  return request({
    url: '/baseAdmin/brand/quick-view',
    method: 'post',
    data,
  })
}

// 创建品牌数据
export function creatBrandData(data) {
  return request({
    url: '/baseAdmin/brand/quick-create',
    method: 'post',
    data,
  })
}

// 排序

export function refreshSort(data) {
  return request({
    url: '/baseAdmin/brand/sort',
    method: 'post',
    data,
  })
}

// 删除
export function deleteBrand(data) {
  return request({
    url: '/baseAdmin/brand/delete',
    method: 'post',
    data,
  })
}

// 编辑
export function editBrand(data) {
  return request({
    url: '/baseAdmin/brand/update',
    method: 'post',
    data,
  })
}

export function editDetail(data) {
  return request({
    url: '/baseAdmin/brand/view',
    method: 'post',
    data,
  })
}

export function creatBrand(data) {
  return request({
    url: '/baseAdmin/brand/create',
    method: 'post',
    data,
  })
}

export function treeDetail(data) {
  return request({
    url: '/baseAdmin/brand/tree',
    method: 'post',
    data,
  })
}
