<template>
  <el-dialog
    :modal="false"
    :title="title"
    :visible.sync="showDialog"
    width="600px"
    top="17vh"
    :close-on-click-modal="false"
    center
  >
    <div
      v-loading="loading"
      class="orderTest-container"
      style="padding: 0 130px"
    >
      <el-form
        ref="form"
        :model="form"
        label-position="right"
        label-width="60px"
        label-suffix=":"
        :rules="type == 1 ? {} : rules"
        :disabled="type == 1 ? true : false"
      >
        <el-form-item label="姓名" prop="truename">
          <el-input v-model="form.truename" placeholder="姓名"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="mobile">
          <el-input v-model="form.mobile" placeholder="电话"></el-input>
        </el-form-item>
        <el-form-item label="账号" prop="username">
          <el-input v-model="form.username" placeholder="账号"></el-input>
        </el-form-item>
        <el-form-item label="密码" prop="password">
          <el-input
            v-model="form.password"
            type="password"
            placeholder="密码"
          ></el-input>
        </el-form-item>
        <el-form-item label="职务" prop="role_id">
          <el-select
            v-model="form.role_id"
            clearable
            style="width: 100%"
            placeholder="职务"
          >
            <el-option
              v-for="(i, idx) in jobList"
              :key="idx"
              :value="i.authority_id"
              :label="i.authority_name"
            ></el-option>
          </el-select>
        </el-form-item>
      </el-form>
    </div>
    <div style="margin-top: 15px; text-align: right">
      <el-button v-if="type == 2" type="primary" @click="handleSub">
        确定
      </el-button>
      <el-button v-if="type == 3" type="primary" @click="handleAdd">
        添加
      </el-button>
      <el-button @click="close">关闭</el-button>
    </div>
  </el-dialog>
</template>
<script>
  import {
    createAdmin,
    jobSelect,
    adminDetail,
    editAdmin,
  } from '@/api/admin/adminAccount'
  import { treeDetail } from '@/api/brand'
  export default {
    name: 'Template',
    components: {},
    props: {},
    data() {
      return {
        loading: false,
        // 1查看  2编辑  3添加
        type: 1,
        title: '标题',
        showDialog: false,
        jobList: [],
        form: {
          truename: '',
          mobile: '',
          username: '',
          password: '',
          role_id: '',
        },
        rules: {
          username: [
            {
              required: true,
              trigger: 'change',
              message: '请输入账号',
            },
          ],
          password: [
            {
              required: true,
              trigger: 'blur',
              message: '请输入密码',
            },
          ],
          role_id: [
            {
              required: true,
              trigger: 'change',
              message: '请输入职务',
            },
          ],
        },
      }
    },
    computed: {},
    watch: {
      showDialog(val) {
        if (val) {
          if (this.type != 3) {
            // 编辑 查看  获取详情
            this.fetchData()
          }
        } else {
          this.clearForm()
        }
      },
    },
    created() {},
    mounted() {
      // 职务下拉
      jobSelect().then((res) => {
        this.jobList = res.data
      })
    },
    methods: {
      // 获取详情
      async fetchData() {
        this.loading = true
        let { data } = await adminDetail({ id: this.id })
        this.form = data
        this.form.role_id = data.role_id + ''
        this.loading = false
      },

      // 编辑提交
      handleSub() {
        this.validateForm(async () => {
          let { msg } = await editAdmin(this.form)
          this.$emit('refresh')
          this.close()
          this.$message.success('修改成功')
        })
      },
      // 添加提交
      async handleAdd() {
        this.validateForm(async () => {
          let { msg } = await createAdmin(this.form)
          this.$emit('refresh')
          this.close()
          this.$message.success('添加成功')
        })
      },
      // 验证表单
      validateForm(cb) {
        this.$refs.form.validate((valid) => {
          if (valid) {
            cb()
          } else {
            return false
          }
        })
      },
      close() {
        this.showDialog = false
      },
      clearForm() {
        this.$refs.form.resetFields()
      },
    },
  }
</script>
<style lang="scss" scoped></style>
