var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "el-dialog",
    {
      attrs: {
        modal: false,
        title: _vm.title,
        visible: _vm.showDialog,
        width: "600px",
        top: "17vh",
        "close-on-click-modal": false,
        center: "",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
      },
    },
    [
      _c(
        "div",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.loading,
              expression: "loading",
            },
          ],
          staticClass: "orderTest-container",
          staticStyle: { padding: "0 130px" },
        },
        [
          _c(
            "el-form",
            {
              ref: "form",
              attrs: {
                model: _vm.form,
                "label-position": "right",
                "label-width": "60px",
                "label-suffix": ":",
                rules: _vm.type == 1 ? {} : _vm.rules,
                disabled: _vm.type == 1 ? true : false,
              },
            },
            [
              _c(
                "el-form-item",
                { attrs: { label: "姓名", prop: "truename" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "姓名" },
                    model: {
                      value: _vm.form.truename,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "truename", $$v)
                      },
                      expression: "form.truename",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "电话", prop: "mobile" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "电话" },
                    model: {
                      value: _vm.form.mobile,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "mobile", $$v)
                      },
                      expression: "form.mobile",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "账号", prop: "username" } },
                [
                  _c("el-input", {
                    attrs: { placeholder: "账号" },
                    model: {
                      value: _vm.form.username,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "username", $$v)
                      },
                      expression: "form.username",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "密码", prop: "password" } },
                [
                  _c("el-input", {
                    attrs: { type: "password", placeholder: "密码" },
                    model: {
                      value: _vm.form.password,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "password", $$v)
                      },
                      expression: "form.password",
                    },
                  }),
                ],
                1
              ),
              _c(
                "el-form-item",
                { attrs: { label: "职务", prop: "role_id" } },
                [
                  _c(
                    "el-select",
                    {
                      staticStyle: { width: "100%" },
                      attrs: { clearable: "", placeholder: "职务" },
                      model: {
                        value: _vm.form.role_id,
                        callback: function ($$v) {
                          _vm.$set(_vm.form, "role_id", $$v)
                        },
                        expression: "form.role_id",
                      },
                    },
                    _vm._l(_vm.jobList, function (i, idx) {
                      return _c("el-option", {
                        key: idx,
                        attrs: {
                          value: i.authority_id,
                          label: i.authority_name,
                        },
                      })
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "margin-top": "15px", "text-align": "right" } },
        [
          _vm.type == 2
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleSub } },
                [_vm._v(" 确定 ")]
              )
            : _vm._e(),
          _vm.type == 3
            ? _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.handleAdd } },
                [_vm._v(" 添加 ")]
              )
            : _vm._e(),
          _c("el-button", { on: { click: _vm.close } }, [_vm._v("关闭")]),
        ],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }